<template v-once>
  <img src="@/assets/icons/jun_group_icon.webp" alt="jun group icon">
</template>

<script>
export default {
  name: "JunGroup",
};
</script>

<style scoped>
</style>
import {rest} from '@/api/rest.api';
import router from '@/router/router';
import lahlouh from "@/assets/icons/lahlouh_icon.png";
import dcc from "@/assets/icons/dcc_icon.png";
import clicksend from "@/assets/icons/clicksend_icon.png";
import twilio from "@/assets/icons/twilio_icon.png";
import typeform from "@/assets/icons/typeform_icon.png";

export const user = {
  namespaced: true,
  state: () => ({
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    gravatarUrl: "",
    selected_account: undefined,
    default_dashboard: null,
    active_roles: [],
    accountTotal: undefined,
    logoutCheckInterval: null,
    lastActivityDateTime: null,
    jwtExpireDateTime: null,
    twoFaExpirationDate: null,
    showAccountsType: 'grid',
  }),
  mutations: {
    update_user_info(state, payload) {
      state.id = payload.id;
      state.email = payload.email;
      state.first_name = payload.firstName;
      state.last_name = payload.lastName;
      state.phone_number = payload.phoneNumber;
      state.gravatarUrl = payload.gravatarUrl;
      state.twoFaExpirationDate = payload.twoFaExpirationDate;

      if (payload.accountTotal) {
        state.accountTotal = payload.accountTotal
      }
    },
    update_selected_account(state, account) {
      state.selected_account = account;
    },
    update_default_dashboard(state, id) {
      state.default_dashboard = id;
    },
    update_roles(state, roles) {
      state.active_roles = roles;
    },
    updateLastActivityDateTime(state, lastActivity) {
      state.lastActivityDateTime = lastActivity;
    },
    updateJwtExpiration(state, jwtExpireDate) {
      state.jwtExpireDateTime = jwtExpireDate;
      localStorage.setItem('jwtExpiry', jwtExpireDate);
    },
    clearLogoutCheck(state) {
      if (!state.logoutCheckInterval) {
        return;
      }

      clearInterval(state.logoutCheckInterval);
      state.logoutCheckInterval = null;
    },
    updateAccountsListView(state, type) {
      state.showAccountsType = type
    }
  },
  getters: {
    user_info: state => ({
      id: state.id,
      email: state.email,
      first_name: state.first_name,
      last_name: state.last_name,
      phone: state.phone_number,
      gravatarUrl: state.gravatarUrl,
      accountTotal: state.accountTotal,
      twoFaExpirationDate: state.twoFaExpirationDate,
    }),
    activeRoles: state => state.active_roles,
    showAccountsType: state => state.showAccountsType,
    roles: state => ({ ...state.active_roles }),
    account: state => ({ ...state.selected_account }),
    activePlugins: state => state.selected_account?.activePlugins?.map(p => p.plugin?.componentName),
    activePluginsList: state => state.selected_account?.activePlugins?.map(p => p.plugin),
    accountHasTopicOptOut(state) {
      return (state.selected_account?.personalizationSettings?.type ?? 'standard') === 'segment';
    },
    customFields: state => state.selected_account?.customFieldDefinition,
    isPulseAdmin: state => state.active_roles[0] === ("ROLE_PULSE_ADMIN"),
    isPulseUser: state => state.active_roles[0] === ("ROLE_USER"),
    isReadOnly: (state, getters) => state.active_roles[0] === ("ROLE_READ_ONLY") || getters.hasWritePermission,
    isReporting: (state, getters) => state.active_roles[0] === ("ROLE_REPORTING") || state.active_roles[0] === ("ROLE_READ_ONLY")
        || getters.hasWritePermission,
    hasNoContactDataRole: (state, getters) => state.active_roles[0] === ("ROLE_NO_CONTACT_DATA")
        || state.active_roles[0] === ("ROLE_READ_ONLY")
        || getters.hasWritePermission,
    hasWritePermission: (state, getters) => getters.isPulseAdmin || getters.isPulseUser,
    defaultDashboard: state => state.default_dashboard,
    isPluginActive: (state) => (componentName) => {
      return !!state.selected_account?.activePlugins?.find(p => p.plugin?.componentName === componentName);
    },
    pluginComponents: (state) => (componentKey) => {
      const activePlugins = state.selected_account?.activePlugins?.map(p => p.plugin?.componentName);

      const components = [];

      if (componentKey === 'contactInfoEmailIcons') {
        if (activePlugins.includes('AddPluginSquintMetrics')) {
          components.push(() => import("@/views/Plugins/SquintMetrics/SquintMetricsEmailIcon"));
        }
      } else if (componentKey === 'contactInfoDataSections') {
        if (activePlugins.includes('AddPluginRepManagement')) {
          components.push(() => import("@/views/Plugins/RepManagement/RepManagementContactDataSection"));
        }
        if (activePlugins.includes('AddPluginVeeva')) {
          components.push(() => import("@/views/Plugins/Veeva/VeevaContactDataSection"));
        }
      } else if (componentKey === 'editFormConfiguration') {
        if (activePlugins.includes('AddPluginTypeform')) {
          components.push(() => import("@/views/Plugins/Typeform/EditTypeformConfiguration"));
        }
      }

      return components;
    },
    directMailAutomationVendors: (state) => {
      const activePlugins = state.selected_account?.activePlugins?.map(p => p.plugin?.componentName);
      const dmVendors = [];

      if (activePlugins.includes('AddPluginLahlouh')) {
        dmVendors.push({
          id: 'Lahlouh',
          name: 'Lahlouh',
          icon: lahlouh,
        });
      }
      if (activePlugins.includes('AddPluginDCC')) {
        dmVendors.push({
          id: 'DCC',
          name: 'DCC',
          icon: dcc,
        });
      }

      return dmVendors;
    },
    smsAutomationVendors: (state) => {
      const activePlugins = state.selected_account?.activePlugins?.map(p => p.plugin?.componentName);
      const smsVendors = [];

      if (activePlugins.includes('AddPluginClickSend')) {
        smsVendors.push({
          id: 'ClickSend',
          name: 'ClickSend',
          icon: clicksend,
        });
      }

      if (activePlugins.includes('AddPluginTwilio')) {
        const twilioPlugin = state.selected_account?.activePlugins.find(p => p.plugin?.componentName === 'AddPluginTwilio');
        if (twilioPlugin?.pluginConfiguration?.phoneNumber) {
          smsVendors.push({
            id: 'Twilio',
            name: 'Twilio',
            icon: twilio,
          });
        }
      }

      return smsVendors;
    },
    formVendors: (state) => {
      const activePlugins = state.selected_account?.activePlugins?.map(p => p.plugin?.componentName);
      const formVendors = [];

      if (activePlugins.includes('AddPluginTypeform')) {
        formVendors.push({
          value: 'typeform',
          label: 'Typeform',
          icon: typeform,
          canConfigureSubmissionRules: true,
        });
      }

      return formVendors;
    },
  },
  actions: {
    async start_logout_check({state, commit}) {
      if (state.logoutCheckInterval) {
        return;
      }

      if (!state.lastActivityDateTime) {
        commit('updateLastActivityDateTime', new Date());
      }

      state.logoutCheckInterval = setInterval(
          () => {
            const lastActivity = state.lastActivityDateTime instanceof Date
              ? state.lastActivityDateTime
              : new Date(state.lastActivityDateTime);

            const secondsSinceLastActivity = (Date.now() - lastActivity.getTime()) / 1000;
            if (secondsSinceLastActivity < parseInt(process.env.VUE_APP_AUTO_LOGOUT_TTL)) {
              // The user is still active, we should check the jwt expiration as well

              if (!state.jwtExpireDateTime) {
                const localStorageValue = localStorage.getItem('jwtExpiry');
                if (localStorageValue) {
                  commit('updateJwtExpiration', new Date(localStorageValue));
                }
              }

              if (!state.jwtExpireDateTime) {
                // Still no expiration date - not able to check if it needs a refresh then
                return;
              }

              const expireDate = state.jwtExpireDateTime instanceof Date
                ? state.jwtExpireDateTime
                : new Date(state.jwtExpireDateTime);

              const secondsUntilExpired = (expireDate.getTime() - Date.now()) / 1000;
              if (secondsUntilExpired <= 300) {
                // The JWT will expire in 5 minutes - lets refresh it now so there's no interruption
                rest.login.refresh().then((resp) => {
                  commit('updateJwtExpiration', new Date(resp.data.expire_date))
                })
              }

              return;
            }

            let redirectPageData = [];
            try {
              redirectPageData = JSON.parse(localStorage.getItem('redirectPage'));
            } catch (e) {
              redirectPageData = null;
            }
            if (redirectPageData && redirectPageData.expiry < Date.now()) {
              localStorage.removeItem('redirectPage');
            }

            if (router.currentRoute.fullPath
                && router.currentRoute.name !== 'Login'
                && router.currentRoute.name !== 'Accounts'
                && router.currentRoute.fullPath !== 'undefined'
                && router.currentRoute.fullPath !== '/'
                && router.currentRoute.name !== "PhoneConfirm"
                && router.currentRoute.name !== "PhoneCodeConfirm"
            ) {
              //Because the Date object is not implemented in JS, but provided by the browser.
              // And each browser uses its own library for working with time.
              let date = new Date()
              date.setUTCHours(date.getUTCHours() + 3);
              // `item` is an object which contains the original value
              // as well as the time when it's supposed to expire
              const item = {
                value: router.currentRoute.fullPath,
                expiry: date.getTime(),
              }
              localStorage.setItem("redirectPage", JSON.stringify(item))
            }

            // Auto-Logout
            rest.login.logout();
            localStorage.removeItem("account");
            localStorage.removeItem("user");
            localStorage.removeItem('jwtExpiry');
            router.push({name: 'Login'})

            commit('clearLogoutCheck');
        },
        60000
      );
    },
    async selectAccount({state, commit}, id, logo) {
      try {
        const selectedAccount = (await rest.account.select_account(id)).data;

        if (!!selectedAccount?.account?.activePlugins && !Array.isArray(selectedAccount?.account?.activePlugins)) {
          selectedAccount.account.activePlugins = Object.values(selectedAccount.account.activePlugins);
        }

        commit('update_default_dashboard', selectedAccount.defaultDashboard.id)
        commit("update_selected_account", {
          ...state.selected_account,
          ...selectedAccount.account,
          logo,
        });

        commit("update_roles", selectedAccount.roles);
        let userDetails = JSON.parse(localStorage.getItem('user'));
        userDetails = {
          ...userDetails,
          roles: selectedAccount.roles,
        };
        localStorage.setItem('user', JSON.stringify(userDetails));
        localStorage.setItem('account', JSON.stringify({
          ...selectedAccount.account,
          logo,
        }));
        localStorage.setItem('defaultDashboard', JSON.stringify(selectedAccount.defaultDashboard));

        return selectedAccount;
      } catch (e) {
        console.error(e);
      }
    }
  }
};
